import Styles from "./styles";
import components from "./components";
import { clickDetail } from "utils/etc";
import { common, ui, layout } from "components";
import { useEffect, useState } from "react";
import { useNavigate, useParams, Link } from "react-router-dom";
import { useAuth, useModals, useReview, useScrap, useWiki } from "hooks";

// img
import IconCat from "resources/image/icon/icon_cat.svg";
import IconLikeOn from "resources/image/icon/icon_like_on.svg";
import IconLikeOff from "resources/image/icon/icon_like_off.svg";
import IconParticipation from "resources/image/icon/icon_participation.svg";
import IconLink from "resources/image/icon/icon_link.svg";
import IconArrowRight from "resources/image/icon/icon_arrow_right.svg";
import defaultImage from "resources/image/img_wiki_default_profile.png";

function WikiDetailPage() {
  const { id } = useParams();
  const navigate = useNavigate();
  const modalOption = useModals();

  const authInfo = useAuth({});
  const scrapInfo = useScrap({ queryName: "wikiDetail" });
  const wikiInfo = useWiki({ detailOption: id });
  const reviewInfo = useReview({
    listOption: {
      relate_info_id: id,
      type: 2,
    },
  });

  const detail = wikiInfo.detailQuery.data?.data
    ? wikiInfo.detailQuery.data?.data
    : {};
  const reviewList = reviewInfo.listQuery.data?.data
    ? reviewInfo.listQuery.data?.data
    : [];
  const reviewDetail = reviewInfo.detailQuery?.data
    ? reviewInfo.detailQuery.data?.data
    : {};

  const [check, setCheck] = useState(0);
  useEffect(() => {
    setCheck(reviewDetail?.score);
  }, [reviewDetail?.score]);

  useEffect(() => {
    wikiInfo.detailQuery?.data?.success === false && wikiInfo.accessRestrict();
  }, [wikiInfo.detailQuery?.data?.success]);

  const buttonList = [
    {
      icon: detail.scrap_yn === "Y" ? IconLikeOn : IconLikeOff,
      text: "스크랩",
      onClick: () => {
        scrapInfo.toggleScrap(id, -1, 2);
      },
    },
    {
      icon: IconParticipation,
      text: "수정참여",
      onClick: () => {
        navigate("/writing", {
          state: {
            type: "Contact us",
            select: { title: "정보수정요청" },
            title: `[ ${detail?.title.trim()} ] 수정 요청드립니다.`,
          },
        });
      },
    },
    {
      icon: IconLink,
      text: "바로가기",
      onClick: () => {
        const detailUrl =
          /https?\:\/\//.test(detail.url) === false ? "http://" : "";
        detail.url && clickDetail(detailUrl + detail.url);
      },
    },
  ];

  return (
    <>
      <common.FixedBg />

      {detail.id && (
        <div className="wrap">
          <common.SubHeader share pageName={"댕댕위키"} />

          <Styles.Container>
            <section className="detailSection">
              <div className="inner">
                <div className="wordContainer">{detail?.subtitle}</div>

                <div className="imgContainer">
                  {detail?.cat_yn == "Y" && (
                    <div className="iconWrap">
                      <img src={IconCat} alt="IconCat" />
                    </div>
                  )}

                  <div className="imgWrap">
                    <img
                      src={detail?.thumbnail ? detail?.thumbnail : defaultImage}
                      alt="img"
                    />
                  </div>
                </div>

                <p className="companyName">{detail?.title}</p>

                <Link to={`/wiki?category_info_id=${detail?.category_info_id}&parent_info_id=${detail?.parent_category_info_id}`} className="subCategory">
                  {detail?.category_title}
                </Link>

                <ul className="utilListWrap">
                  {buttonList.map((item, index) => {
                    return (
                      <li className="utilList" key={"utilList" + index}>
                        <button type="button" onClick={item.onClick}>
                          <span className="iconWrap">
                            <img src={item.icon} alt={item.icon} />
                          </span>
                          {item.text}
                        </button>
                      </li>
                    );
                  })}
                </ul>

                <ul className="listWrap">
                  {detail?.address && (
                    <li className="list">
                      <p className="title">주소</p>

                      <p className="description">
                        {detail?.address}
                        <button
                          type="button"
                          onClick={() => {
                            detail?.address_url &&
                              clickDetail(detail?.address_url);
                          }}
                          target={"_blank"}
                        >
                          지도보기
                        </button>
                      </p>
                    </li>
                  )}
                  {detail?.extra_list?.map((item, index) => {
                    return (
                      <li className="list" key={"list" + index}>
                        <p className="title">{item.title}</p>

                        <p className="description">{item.description}</p>
                      </li>
                    );
                  })}
                </ul>

                {detail?.link_list?.length > 0 && (
                  <ul className="linkListWrap">
                    {detail?.link_list?.map((item, index) => {
                      return (
                        <li
                          className="linkList"
                          key={"linkList" + index}
                          onClick={() => {
                            // 새 창으로
                            item.url && clickDetail(item.url);
                          }}
                        >
                          <div className="iconWrap">
                            <img src={item.image} alt={item.image} />
                          </div>

                          <p className="description">{item.title}</p>
                        </li>
                      );
                    })}
                  </ul>
                )}

                {detail?.description && (
                  <p
                    className="detail editor ck-content"
                    dangerouslySetInnerHTML={{ __html: detail?.description }}
                  ></p>
                )}

                {detail?.keyword_list?.length > 0 && (
                  <ul className="keywordListWrap">
                    {detail?.keyword_list?.map((item, index) => {
                      return (
                        <li className="keywordList" key={"keywordList" + index}>
                          <ui.button.Keyword
                            text={item.title}
                            onClick={() => {
                              navigate(`/search/detail?keyword=${item.title}`);
                            }}
                          />
                        </li>
                      );
                    })}
                  </ul>
                )}
              </div>
            </section>

            <section className="reviewSection">
              <div className="inner">
                <p className="reviewTitle">
                  수발러 경험 &nbsp; <span>{reviewDetail?.total_count}</span>
                </p>

                <div className="textContainer">
                  <p className="reviewText">
                    다른 수발러들을 위해 <br />
                    여러분의 솔직한 경험담을 나눠주세요.
                  </p>

                  <p className="average">평균 온도 {reviewDetail?.score}℃</p>
                </div>

                <layout.GuageContainer
                  check={check}
                  setCheck={setCheck}
                  disabled
                  id={"wiki"}
                  name={"wikiTemperature"}
                />

                <button
                  type="button"
                  className="reviewButton"
                  onClick={() => {
                    if (authInfo.certification()) {
                      if (
                        reviewList.filter((v) => {
                          return v.write_yn === "Y";
                        }).length === 0
                      ) {
                        modalOption.setReviewModal((e) => {
                          e.show = true;
                          e.edit = false;
                          e.reviewInfo = reviewInfo;
                          return { ...e };
                        });
                      } else {
                        reviewInfo.blockReview();
                      }
                    }
                  }}
                >
                  경험 나누기
                  <span className="iconWrap">
                    <img src={IconArrowRight} alt="IconArrowRight" />
                  </span>
                </button>

                <ul className="reviewListWrap">
                  {reviewList?.map((item, index) => {
                    return (
                      <li className="reviewList" key={"reviewList" + index}>
                        <components.ReviewList
                          data={item}
                          reviewInfo={reviewInfo}
                          reportCallback={() => {
                            reviewInfo.listQuery.refetch();
                            reviewInfo.detailQuery.refetch();
                          }}
                        />
                      </li>
                    );
                  })}
                </ul>
              </div>
            </section>
          </Styles.Container>

          <common.QuickMenu />

          <common.NavigationBar />
        </div>
      )}
    </>
  );
}

export default WikiDetailPage;
