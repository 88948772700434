import useModals from "hooks/useModals";
import { api } from "service";
import { DOMAIN } from "service/connector";
import { useAtom } from "jotai";
import { authAtom } from "store/atoms";
import { useQuery } from "react-query";
import { useNavigate } from "react-router-dom";
import { etc } from "utils";

export default function useAuth({ detail }) {
  const navigate = useNavigate();
  const modalOption = useModals();
  const [userData, setUserData] = useAtom(authAtom.userInfo);
  const isLogin =
    userData.nickname == "" || userData.nickname == null ? false : true;

  const userQuery = useQuery("userData", api.auth.getUser, {
    onSuccess: (e) => {
      if (e == null) {
      } else {
        setUserData(e);
      }
    },
    onError: (error) => {
      console.log(error);
    },
    refetchOnWindowFocus: false,
  });

  const findNickName = async (userNickName) => {
    return await api.auth.getCheckName(userNickName);
  };

  const certification = () => {
    if (isLogin === false) {
      modalOption.setDefaultModal((e) => {
        e.show = true;
        e.title = (
          <>
            펫트워크 <span className="color">회원</span>이 되어보세요!
          </>
        );
        e.text = (
          <>
            간단한 소셜 로그인을 통해 <br />
            나만의 아카이빙이 가능합니다.
          </>
        );
        e.confirmButtonText = "로그인 / 회원가입";
        e.confirmButtonClick = () => {
          navigate("/login");
        };
        e.cancelButtonText = "닫기";
        e.cancelButtonClick = () => {};
        return { ...e };
      });
      return false;
    }
    return true;
  };

  const getValidateToken = async () => {
    let result = await api.auth.getValidateToken();
    if (result && result.success == false) {
      logout();
      window.location.replace("/");
    }
  };

  const socialLogin = async (type) => {
    sessionStorage.removeItem("userInfo");
    return await api.auth.socialLogin(type);
  };
  const socialSignUp = async (data) => {
    const result = await api.auth.socialSignUp(JSON.stringify(data));
    if (result.success) {
      document.cookie = `jwt=${result?.data};path=/;domain=${DOMAIN}`;
      await userQuery.refetch();
      result.success &&
        navigate("/signup/complete", { state: true, replace: true });
    }
  };
  const userDetail = useQuery(
    ["user_detail", detail],
    () => {
      return api.auth.userDetail(detail);
    },
    {
      enabled: !!detail,
      refetchOnWindowFocus: false,
    }
  );
  const userUpdate = async (data) => {
    data.current_amount = data.current_amount === "" ? 0 : data.current_amount;
    data.prev_amount = data.prev_amount === "" ? 0 : data.prev_amount;

    const result = await api.auth.userUpdate(JSON.stringify(data));
    if (result.success) {
      modalOption.setDefaultModal((e) => {
        e.show = true;
        e.title = "수정이 완료되었습니다.";
        e.text = false;
        e.confirmButtonText = "확인";
        e.confirmButtonClick = () => {
          navigate("/mypage");
        };
        e.cancelButtonText = false;
        return { ...e };
      });
    }
  };

  const logout = () => {
    setUserData(authAtom.userInfo);
    document.cookie = `jwt=;path=/;domain=${DOMAIN};max-age:-1`;
    document.cookie = `jwt=;path=/;domain=.${DOMAIN};max-age:-1`;
    document.cookie = `jwt=;path=/;domain=localhost;max-age:-1`;
    document.cookie = `jwt=;path=/;domain=.localhost;max-age:-1`;

    if (window.ReactNativeWebView && window.ReactNativeWebView.postMessage) {
      window.ReactNativeWebView.postMessage(JSON.stringify({ type: "LOGOUT" }));
    }

    // document.cookie = `jwt=; expires=Thu, 01 Jan 1999 00:00:10 GMT; path=/; domain=.${DOMAIN};`;
    // document.cookie = `jwt=; expires=Thu, 01 Jan 1999 00:00:10 GMT; path=/; domain=${DOMAIN};`;
    // document.cookie = `jwt=; expires=Thu, 01 Jan 1999 00:00:10 GMT; path=/; domain=.localhost.org;`;
    // document.cookie = `jwt=; expires=Thu, 01 Jan 1999 00:00:10 GMT; path=/; domain=localhost.org;`;
  };

  const logoutModal = async () => {
    modalOption.setDefaultModal((e) => {
      e.show = true;
      e.title = "로그아웃 하시겠습니까?";
      e.text = (
        <>
          로그인 상태를 유지하는 편이 편하게 <br />
          댓글이나 스크랩을 이용하실 수 있습니다.
        </>
      );
      e.confirmButtonText = "로그아웃";
      e.confirmButtonClick = () => {
        logout();
        modalOption.setDefaultModal((e) => {
          e.show = true;
          e.title = "로그아웃 되었습니다!";
          e.text = false;
          e.confirmButtonText = "메인으로";
          e.confirmButtonClick = () => {
            window.location.replace("/");
          };
          e.cancelButtonText = false;
          return { ...e };
        });
      };
      e.cancelButtonText = "취소";
      e.cancelButtonClick = false;
      return { ...e };
    });
  };

  const resign = async (inputs, modalClose) => {
    const result = await api.auth.resign(
      JSON.stringify({
        reason: inputs.reason == "기타" ? inputs.textarea : inputs.reason,
      })
    );
    if (result?.success) {
      modalClose();
      logout();
      modalOption.setDefaultModal((e) => {
        e.show = true;
        e.title = "회원님과 함께해서 즐거웠습니다!";
        e.text = "향후 더 발전하도록 하겠습니다.";
        e.confirmButtonText = "메인으로";
        e.confirmButtonClick = () => {
          window.location.replace("/");
        };
        e.cancelButtonText = false;
        return { ...e };
      });
    }
  };

  return {
    userData,
    setUserData,
    isLogin,
    getValidateToken,

    isLoad: userQuery.isLoading,

    findNickName,

    certification,

    socialLogin,
    socialSignUp,
    userDetail,
    userUpdate,
    logoutModal,
    resign,
  };
}
