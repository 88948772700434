import Styles from "./styles";
import { layout } from "components";
import { useScrap } from "hooks";
import { useNavigate } from "react-router-dom";

// img
import IconMore from "resources/image/icon/icon_more02.svg";

function AllResult({ list, onClick, keyword, filter }) {
  const navigate = useNavigate();
  const scrapInfo = useScrap({
    queryName: "search_list",
    callFilter: filter,
  });

  const renderComponents = (current, index, idx) => {
    switch (index) {
      case 0:
        return <layout.WikiList data={current} keyword={keyword} />;
      case 1:
        return (
          <layout.MagazineList
            data={current}
            index={idx}
            keyword={keyword}
            scrapInfo={scrapInfo}
          />
        );
      case 2:
        return (
          <layout.CommunityList
            data={current}
            index={idx}
            keyword={keyword}
            scrapInfo={scrapInfo}
          />
        );
      case 3:
        return (
          <layout.EventList
            data={current}
            index={idx}
            keyword={keyword}
            scrapInfo={scrapInfo}
          />
        );
    }
  };

  return (
    <>
      {list?.map((item, index) => {
        return (
          <Styles.Container key={"section" + index}>
            <div className="inner">
              <h2>
                {item.title}{" "}
                <span>
                  (검색결과 {item.data?.pagination?.totalRecordCountAll || 0}개)
                </span>
              </h2>

              {item.data?.list?.length > 0 ? (
                <>
                  <ul className="listWrap">
                    {item.data.list?.slice(0, 3).map((current, idx) => {
                      return (
                        <li
                          className={item.class + "List"}
                          key={item.class + "list" + idx}
                          onClick={() => {
                            navigate(`/${item.class}/detail/${current.id}`);
                          }}
                        >
                          {renderComponents(current, index, idx)}
                        </li>
                      );
                    })}
                  </ul>

                  <button
                    type="button"
                    className="moreSearchButton"
                    onClick={() => {
                      onClick(index + 1);
                    }}
                  >
                    <span className="iconWrap">
                      <img src={IconMore} alt="IconMore" />
                    </span>
                    검색결과 더보기
                  </button>
                </>
              ) : (
                <div className="emptyContainer">
                  <layout.EmptyContainer
                    search
                    text={"검색 결과가 없습니다."}
                  />
                </div>
              )}
            </div>
          </Styles.Container>
        );
      })}
    </>
  );
}

export default AllResult;
