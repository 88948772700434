import utils from "utils";
import Styles from "./styles";
import components from "./components";
import { useEvent, useMagazine, useSearch, useSubaler, useWiki } from "hooks";
import { common, ui, layout } from "components";
import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";

function SearchDetailPage() {
  const location = useLocation();
  const navigate = useNavigate();
  const [search, setSearch] = useState(
    decodeURIComponent(utils.etc.getSearchParam().keyword) || ""
  );
  const [tabIndex, setTabIndex] = useState(0);
  const [filter, setFilter] = useState(null);
  const searchInfo = useSearch({
    // listOption: filter,
    // keywordOption: {
    //   keyword: search
    // }
  });

  const eventInfo = useEvent({
    listOption: { ...filter, type: 1 },
  });

  const wikiInfo = useWiki({
    listOption: { ...filter, type: 2 },
  });

  const magazineInfo = useMagazine({
    listOption: { ...filter, type: 3 },
  });

  const subalerInfo = useSubaler({
    listOption: { ...filter, type: 4 },
  });

  const wikiData = wikiInfo.listQuery?.data ? wikiInfo.listQuery?.data : null;
  const magazineData = magazineInfo.listQuery?.data
    ? magazineInfo.listQuery?.data
    : null;
  const subalerData = subalerInfo.listQuery?.data
    ? subalerInfo.listQuery?.data
    : null;
  const eventData = eventInfo.listQuery?.data
    ? eventInfo.listQuery?.data
    : null;

  const searchKeywordList = searchInfo.searchKeywordQuery.data?.data || [];
  const data = searchInfo.listQuery.data?.data || [];

  const tabList = [
    { text: "종합" },
    { text: "댕댕위키" },
    { text: "매거진" },
    { text: "수발러 게시판" },
    { text: "행사와 지원혜택" },
  ];

  const list = [
    {
      title: "댕댕위키",
      data: wikiData,
      class: "wiki",
    },
    {
      title: "매거진",
      data: magazineData,
      class: "magazine",
    },
    {
      title: "수발러 게시판",
      data: subalerData,
      class: "community",
    },
    {
      title: "행사와 지원혜택",
      data: eventData,
      class: "event",
    },
  ];

  useEffect(() => {
    let param = utils.etc.getSearchParam();
    setTabIndex(param.tab ? parseInt(param.tab) : 0);
    if ((param.tab ? parseInt(param.tab) : 0) == 0) {
      let f = {
        page: 1,
        recordSize: 4,
        pageSize: 1,
        keyword: param.keyword ? decodeURIComponent(param.keyword) : "",
      };

      setFilter(f);
    }
  }, [location]);

  return (
    <>
      <common.FixedBg />

      <div className="wrap">
        <common.Header />

        <Styles.Container>
          <h2 className="pageName">검색</h2>

          <section className="searchSection">
            <div className="inner">
              <layout.SearchContainer
                main
                search={search}
                setSearch={setSearch}
                relateList={searchKeywordList}
              />
            </div>

            <ui.button.Tab
              tabList={tabList}
              tabIndex={tabIndex}
              setTabIndex={(e) => {
                if (e == 0)
                  navigate(
                    utils.etc.getQueryParamUrl(
                      { tab: null, keyword: search },
                      true
                    )
                  );
                else
                  navigate(
                    utils.etc.getQueryParamUrl(
                      { tab: e, keyword: search },
                      true
                    )
                  );
              }}
            />
          </section>
          {tabIndex === 0 ? (
            <components.AllResult
              keyword={search}
              type={data.map((item) => {
                return item.type;
              })}
              list={list}
              onClick={(e) => {
                navigate(utils.etc.getQueryParamUrl({ tab: e }));
              }}
              filter={filter}
            />
          ) : (
            <components.Result
              data={list[tabIndex - 1]}
              idx={tabIndex - 1}
              keyword={search}
              filter={filter}
              setFilter={setFilter}
            />
          )}
        </Styles.Container>

        <common.QuickMenu />

        <common.NavigationBar />
      </div>
    </>
  );
}

export default SearchDetailPage;
